// import 'icofont/icofont.min.css';
import React from "react";
import '../WhyUs/why.css';
import { FaUserTie, FaUniversity, FaChalkboardTeacher, FaUsers } from 'react-icons/fa';
import SlideShow1 from '../Assets/slideshow-bg1.jpg';
import SlideShow2 from '../Assets/slideshow-bg2.jpg';
import SlideShow3 from '../Assets/slideshow-bg3.jpg';
import SlideShow4 from '../Assets/slideshow-bg4.jpg';

const WhyUsData = [
  {
    icon: <FaUserTie/>,
    title: "Konsultasi Ekslusif",
    shortText:
      "Tanya apapun, info kampus biaya kuliah, info beasiswa, biaya hidup, beasiswa, part-time job, dll. Kamu juga bisa tanya kampus dari negara-negara impianmu. Kamu juga bisa konsultasi bahasa inggris, talent mapping, dan apapun yang dapat membantumu untuk kuliah di luar negeri"
  },
  {
    icon: <FaUniversity/>,
    title: "Banyak Pilihan Kampus",
    shortText:
      "Ada banyak pilihan kampus mulai dari benua Asia, Amerika, hingga Eropa. Kamu bisa konsultasi langsung dengan para perwakilan kampusnya. Konsultasimu akan semakin nyaman karena ditemani Interpreter, jadi jangan merasa malu jika bahasa inggrismu kurang"
  },
  {
    icon: <FaChalkboardTeacher/>,
    title: "Workshop Gratis Berkualitas",
    shortText:
      "Jangan sia-siakan waktumu. Selesai konsultasi, kamu bisa upgrade skill dengan mengikuti workshop dengan pembicara yang kompeten di bidangnya"
  },
  {
    icon: <FaUsers/>,
    title: "Jaringan Komunitas",
    shortText:
      "Perluas informasi tentang kuliah luar negeri dengan para komunitas yang hadir. Kamu akan bertemu dengan teman-teman yang punya minat sama untuk kuliah luar negeri"
  },
];

const WhyUs = () => {
  return (
    <div className="why-choose-us">
      <div className="row m-0 justify-content-center">
        {WhyUsData.slice(0, 4).map((item, index) => (
          <div className="col-lg-3 col-sm-6" key={index}>
            <div className="single-box">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="content">
                    <div className="icon">
                      <i className={item.icon}></i>
                    </div>
                    <h3>{item.title}</h3>
                    <p>{item.shortText}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ul className="slideshow">
        <li>
          <span style={{ backgroundImage: `url(${SlideShow1})` }}></span>
        </li>
        <li>
          <span style={{ backgroundImage: `url(${SlideShow2})` }}></span>
        </li>
        <li>
          <span style={{ backgroundImage: `url(${SlideShow3})` }}></span>
        </li>
        <li>
          <span style={{ backgroundImage: `url(${SlideShow4})` }}></span>
        </li>
      </ul>
    </div>
  );
};

export default WhyUs;
