import 'bootstrap/dist/css/bootstrap.min.css';
// import 'icofont/icofont.min.css';
import Navbar from './Navbar/navbar.jsx';
import CountDown from './CountDown/CountDown.jsx';
import Form from './Form/Form.jsx'
import About from './About/about.jsx'
import WhyUs from './WhyUs/why.jsx';
import Speaker from './Speaking/speaking.jsx'
import Schedule from './Schedulee/schedule.jsx'
import Milestone from './Milestone/milestone.jsx'
// import News from './News/news.jsx'
import Footer from './Footer/footer.jsx'
// import MainBanner from './MainBanner/mainbanner.jsx';

function App() {
  return (
    <>
      <Navbar />
      {/* <MainBanner /> */}
      <CountDown />
      <Form />
      <About />
      <WhyUs />
      <Speaker />
      <Schedule />
      <Milestone />
      {/* <News /> */}
      <Footer />
    </>
  );
}

export default App;
